import Author from 'components/modules/Author';
import VideoPlayer from 'components/modules/VideoPlayer';
import Layout from 'components/template/Layout';
import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetUserDataByUsername,
  useDefaultColorValue,
} from 'services/queries/app';
import Button from 'components/base/Button';
import FeaturedResource from 'components/modules/FeaturedResource';
import Title from 'components/modules/Title';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useFeaturedContext } from 'provider/FeaturedProvider/FeaturedProvider';
import AudioPlayer from 'components/modules/AudioPlayer';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Preloader from 'components/base/Preloader';
import { getUrlWithProtocol } from 'utils/getUrlWithProtocol';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import InnerHTML from 'dangerously-set-html-content';
import { StyledBgContainer } from 'pages/Content/Content.styles';
import { PageNotFoundLoggedOut } from 'pages/ActionForms/PromptMessage';
import { useGetContentByUuid } from '../../services/queries/content';

const Products = () => {
  useGetUserDataByUsername();
  const navigate = useNavigate();
  const todayDate = new Date();

  const { uuid, type } = useParams();
  const { loggedInUser } = useUserContext();
  const { status, data } = useGetContentByUuid(uuid, loggedInUser?.id_token);
  const colors = useDefaultColorValue();
  const {
    setup: { fonts },
    baseURL,
    setTitle,
    setDescription,
  } = useAppContext();

  const { topFeatured, topFeaturedStatus } = useFeaturedContext();

  const [showPreloader, setShowPreloader] = useState<'loading' | 'success'>(
    'loading',
  );
  const [svgColor, setSvgColor] = useState(colors.medium);

  useEffect(() => {
    setTimeout(() => {
      setShowPreloader('success');
    }, 750);
  }, []);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
      setDescription(data.description);
    }
  }, [data]);

  if (
    status === 'success' &&
    (typeof data === 'undefined' ||
      new Date(String(data?.release_date)) > todayDate)
  ) {
    return <PageNotFoundLoggedOut />;
  }

  const isLoading =
    showPreloader === 'loading' ||
    status === 'loading' ||
    topFeaturedStatus === 'loading';

  let showVideo = false;
  let showAudio = false;

  if (!isEmpty(data?.video_url)) showVideo = true;

  if (!showVideo && !isEmpty(data?.audio_url)) showAudio = true;

  const featuredResource = topFeatured?.find(
    (item) => item.content_type === 'resources',
  );

  let contentDescription = data?.description;

  if (data?.web_description) {
    contentDescription = data?.web_description;
  }

  return (
    <Layout>
      {isLoading ? (
        <StyledBgContainer
          bgColor={colors.light}
          className="flex flex-col h-full mx-auto"
        >
          <div className="container mx-auto px-4 md:px-0 pt-11">
            <Preloader />
            <Preloader />
          </div>
        </StyledBgContainer>
      ) : (
        <StyledBgContainer
          bgColor={colors.light}
          className="flex flex-col justify-between h-full"
        >
          <div className="container mx-auto px-0">
            <div className="pt-11">
              {/* @TODO: Change to subhead once breadcrumbs is done */}
              {(data?.title ?? '') !== '' && type !== 'questions' ? (
                <div className="flex justify-start items-center pb-4 pr-4 md:pr-0">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    style={{ color: colors.medium }}
                  >
                    <RiArrowLeftSLine
                      size="64"
                      color={svgColor}
                      onMouseEnter={() => setSvgColor(colors.dark)}
                      onMouseLeave={() => setSvgColor(colors.medium)}
                    />
                  </button>
                  <h1
                    className="text-3xl font-bold mb-0 pl-4 ttci-break-word"
                    style={{ color: colors.dark, fontFamily: fonts.header }}
                  >
                    {data?.title}
                  </h1>
                </div>
              ) : null}
            </div>

            {(data?.title ?? '') !== '' && type === 'questions' ? (
              <div className="flex justify-center items-center pb-4">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  style={{ color: colors.medium }}
                  className="flex-1 min-w-fit"
                >
                  <RiArrowLeftSLine
                    size="64"
                    color={svgColor}
                    onMouseEnter={() => setSvgColor(colors.dark)}
                    onMouseLeave={() => setSvgColor(colors.medium)}
                  />
                </button>
                <Title
                  fontSize="36px"
                  className="mb-0 pl-4 flex-auto text-center"
                >
                  {data?.title ?? ''}
                </Title>
                <div className="flex-1" />
              </div>
            ) : null}

            {!data?.review_video_url && !isEmpty(data?.review_image_url) ? (
              <div className="py-4">
                <div className="flex flex-col px-4 lg:px-0 items-center justify-center xl:w-[1056px] mx-auto">
                  <img
                    className="max-w-full object-scale-down rounded-lg min-h-[100px]"
                    src={data?.review_image_url}
                    alt="headline-img"
                  />
                </div>
              </div>
            ) : null}

            {!isEmpty(data?.review_video_url) ? (
              <div className="flex flex-col px-4 lg:px-0  items-center justify-center xl:w-[1056px] xl:h-[575px] mx-auto mb-4">
                <VideoPlayer url={data?.review_video_url ?? ''} />
              </div>
            ) : null}

            {(data?.price ?? '') !== '' ? (
              <h2 className="text-center font-bold text-2xl">
                {data?.price ?? ''}
              </h2>
            ) : null}
            <div className="flex flex-col justify-center items-center p-4 md:p-8 pt-0">
              <InnerHTML
                html={
                  contentDescription === null ||
                  contentDescription === '' ||
                  typeof contentDescription === 'undefined'
                    ? '<p></p>'
                    : contentDescription
                }
                className={`inner-html text-black text-lg text-justify w-full mx-auto pt-8 mb-6 ttci-break-word ${
                  data?.web_description ? 'break-words' : 'whitespace-pre-wrap'
                }`}
              />
            </div>

            {data?.video_url ? (
              <div className="pt-4">
                <div className="flex flex-col px-4 lg:px-0 items-center justify-center xl:w-[1056px] xl:h-[575px] mx-auto">
                  <VideoPlayer url={data?.video_url ?? ''} />
                </div>
              </div>
            ) : null}

            {showAudio ? (
              <div className="py-4">
                <div className="flex flex-col px-4 lg:p-0 items-center justify-center xl:w-[1056px] mx-auto">
                  <AudioPlayer srcLink={data?.audio_url ?? ''} />
                </div>
              </div>
            ) : null}

            {!data?.video_url && data?.image_url ? (
              <div className="py-4">
                <div className="flex flex-col px-4 lg:px-0 items-center justify-center xl:w-[1056px] mx-auto">
                  <img
                    className="max-w-full object-scale-down rounded-lg min-h-[100px]"
                    src={data?.image_url}
                    alt="headline-img"
                  />
                </div>
              </div>
            ) : null}

            <div className="flex flex-col justify-center items-center p-4 md:p-8 pt-0">
              <Author
                name={data?.author ?? ''}
                color="#888888"
                shouldRemoveBy
              />

              {(data?.action_bar_text ?? '') !== '' && (
                <div className="w-full flex items-center justify-center md:w-fit py-4 -px-4 md:p-4">
                  {data?.action_bar_url !== '' ? (
                    <a
                      href={getUrlWithProtocol(data?.action_bar_url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button>{data?.action_bar_text}</Button>
                    </a>
                  ) : (
                    <Button>{data?.action_bar_text}</Button>
                  )}
                </div>
              )}
            </div>
          </div>

          {topFeaturedStatus !== 'loading' &&
          typeof featuredResource !== 'undefined' ? (
            <FeaturedResource
              title={
                featuredResource?.title !== undefined
                  ? featuredResource?.title
                  : ''
              }
              image={
                featuredResource?.image_url !== undefined
                  ? featuredResource?.image_url
                  : ''
              }
              description={
                featuredResource?.description !== undefined
                  ? featuredResource?.description
                  : ''
              }
              targetUrl={`${String(baseURL)}/${String(
                featuredResource?.content_type,
              )}/${String(featuredResource?.id)}`}
            />
          ) : null}
        </StyledBgContainer>
      )}
    </Layout>
  );
};

export default Products;
