import * as React from 'react';
import { useEffect, useRef } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useAppContext } from 'provider/AppProvider/AppProvider';
import { AiFillCaretDown } from 'react-icons/ai';
import smiley from 'assets/images/smiley.png';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useDefaultColorValue } from 'services/queries/app';
import { useMutation, useQueryClient } from 'react-query';
import { subscribe, unsubscribe } from 'services/api/user';
import { FiLogIn, FiUserPlus } from 'react-icons/fi';
import { useAuth } from 'react-oauth2-pkce';
import Logo from '../Logo';
import useWindowSize from '../../../hooks/useWindowSize';
import MenuList from './MenuList';
import { StyledButton } from './Navbar.styles';
import LoginSidebar from './LoginSidebar/LoginSideBar';

const Navbar = () => {
  const { width } = useWindowSize();
  const queryClient = useQueryClient();
  const colors = useDefaultColorValue();

  const {
    setup: {
      header: { tagline },
    },
    isSubscribed,
    uuid,
    baseURL,
  } = useAppContext();

  const { login, logout, isLoggedIn, loggedInUser } = useUserContext();

  const { authService } = useAuth();
  const token = authService.getAuthTokens().id_token;

  const wrapperRef = useRef<HTMLInputElement>(null);
  const rightNavbarRef = useRef<HTMLInputElement>(null);

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [rightNavbarOpen, setRightNavbarOpen] = React.useState(false);

  const name =
    localStorage?.getItem('name')?.split(' ') ??
    loggedInUser?.data?.name.split(' ');
  const lastName =
    typeof name !== 'undefined' && name?.length > 1
      ? ` ${name?.[name.length - 1]?.slice(0, 1)}.`
      : '';
  const creatorName = `${name?.[0] ?? ''}${lastName}`;

  const navigate = useNavigate();
  useEffect(() => {
    function handleClickOutside(ev: MouseEvent) {
      if (
        wrapperRef?.current != null &&
        !wrapperRef.current.contains(ev.target as Element)
      ) {
        setNavbarOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutside(ev: MouseEvent) {
      if (
        rightNavbarRef?.current != null &&
        !rightNavbarRef.current.contains(ev.target as Element)
      ) {
        setRightNavbarOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [rightNavbarRef]);

  useEffect(() => {
    if ((width ?? 0) >= 1024) {
      handleCloseNavbar();
    }
  }, [width]);

  const handleCloseNavbar = () => {
    setNavbarOpen(false);
    setRightNavbarOpen(false);
  };

  const handleLogout = async () => {
    logout();
  };

  const handleSubscribe = async () => {
    return await subscribe(uuid, token);
  };

  const handleUnsubscribe = async () => {
    return await unsubscribe(uuid, token);
  };

  const { mutate: onSubscribe } = useMutation(handleSubscribe, {
    onSuccess() {
      window.location.reload();
    },
    // eslint-disable-next-line no-alert
    onError: (e) => alert(e),
    onSettled: async () => {
      await queryClient.invalidateQueries('subscribe');
    },
  });

  const { mutate: onUnsubscribe } = useMutation(handleUnsubscribe, {
    onSuccess() {
      navigate(`${String(baseURL)}/`);
    },
    // eslint-disable-next-line no-alert
    onError: (e) => alert(e),
    onSettled: async () => {
      await queryClient.invalidateQueries('unsubscribe');
    },
  });

  const handleSubscription = async () => {
    !isSubscribed ? onSubscribe() : onUnsubscribe();
  };

  const handleOnClickLogin = async () => login();

  return (
    <div className={navbarOpen ? 'mb-[104px] z-50 opacity-1 bg-white' : ''}>
      <div
        className={
          navbarOpen
            ? 'bg-gradient-to-r from-black to-transparent h-full w-full fixed z-50'
            : ''
        }
      />

      <div
        className={
          rightNavbarOpen
            ? 'bg-gradient-to-l from-black to-transparent h-full w-full fixed z-50'
            : ''
        }
      />

      <button
        className={`
          text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded lg:hidden outline-none focus:outline-none
          ${navbarOpen ? 'fixed top-[30px] left-56 z-50' : 'hidden'}
        `}
        type="button"
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        <GiHamburgerMenu size={40} color={navbarOpen ? 'white' : colors.dark} />
      </button>
      <div
        className={`top-0 left-0 z-50 w-56 bg-white text-white fixed flex flex-col overflow-y-auto h-full ${
          navbarOpen ? 'flex' : 'hidden'
        }`}
        ref={wrapperRef}
      >
        <div className="w-full py-3 relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className="w-full flex justify-between items-center">
            <Link to={`${String(baseURL)}/`}>
              <div className="px-5">
                <Logo
                  className="max-h-[50px] lg:max-h-[96px]"
                  logoTextStyle="text-lg"
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex-grow items-center" id="navbar">
          <MenuList tagline={tagline} handleCloseNavbar={handleCloseNavbar} />
        </div>
      </div>
      <nav
        className={`w-full flex flex-wrap items-center justify-between py-3 px-4 lg:px-0 bg-white h-auto ${
          navbarOpen ? 'fixed z-40' : 'flex'
        }`}
      >
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="flex items-center justify-center">
              <button
                className="text-white cursor-pointer text-xl leading-none pr-2 py-1 border border-solid border-transparent rounded block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <GiHamburgerMenu size={40} color={colors.dark} />
              </button>
              <Link to={`${String(baseURL)}/`}>
                <Logo
                  className="max-h-[50px] lg:max-h-[96px]"
                  logoTextStyle="text-lg"
                />
              </Link>
            </div>
            <div className="flex lg:hidden justify-center items-center">
              {isLoggedIn && !isSubscribed ? (
                <div className="flex lg:hidden justify-center items-center">
                  <StyledButton
                    type="button"
                    colors={colors}
                    className="text-xs uppercase rounded-full p-2 border-none font-bold"
                    onClick={handleSubscription}
                  >
                    Be a member
                  </StyledButton>
                </div>
              ) : null}
              {isLoggedIn ? (
                <button
                  type="button"
                  className="lg:min-w-[150px] w-fit h-fit flex flex-row justify-center items-center"
                  onClick={() => setRightNavbarOpen(!navbarOpen)}
                >
                  <img
                    src={smiley}
                    alt="logged in"
                    className="w-[40px] h-[40px]"
                  />
                  <AiFillCaretDown size={12} color={colors.medium} />
                </button>
              ) : (
                <>
                  <NavLink to={`${String(baseURL)}/user/sign_up`}>
                    <button
                      type="button"
                      className="flex items-center mr-2 text-[18px]"
                    >
                      <FiUserPlus color={colors.medium} />
                      <span
                        style={{ color: colors.dark }}
                        className="text-xs sm:text-sm md:text-base ml-2 underline hover:no-underline"
                      >
                        Sign Up
                      </span>
                    </button>
                  </NavLink>
                  <button
                    type="button"
                    className="flex items-center text-[18px]"
                    onClick={handleOnClickLogin}
                  >
                    <FiLogIn color={colors.medium} />
                    <span
                      style={{ color: colors.dark }}
                      className="text-xs sm:text-sm md:text-base ml-2 underline hover:no-underline whitespace-nowrap"
                    >
                      Login
                    </span>
                  </button>
                </>
              )}
            </div>
            <LoginSidebar
              rightNavbarOpen={rightNavbarOpen}
              setRightNavbarOpen={setRightNavbarOpen}
              handleLogout={handleLogout}
              creatorName={creatorName}
            />
          </div>

          <div>
            {!isLoggedIn && (
              <NavLink to={`${String(baseURL)}/user/sign_up`}>
                <StyledButton
                  // type="button"
                  colors={colors}
                  className="uppercase rounded-full py-2 border-none font-bold px-8 hidden lg:flex"
                  // onClick={handleOnClickLogin}
                >
                  Free Sign Up
                </StyledButton>
              </NavLink>
            )}

            <div className="flex flex-col">
              {!isSubscribed && isLoggedIn && (
                <StyledButton
                  type="button"
                  colors={colors}
                  className="uppercase rounded-full py-2 border-none font-bold px-8 hidden lg:flex"
                  onClick={async () => await handleSubscription()}
                >
                  Become a member
                </StyledButton>
              )}
            </div>
          </div>
        </div>

        <div className="md:mt-3 container mx-auto flex flex-wrap items-center justify-between">
          <div
            className="hidden lg:flex flex-grow items-center justify-center"
            id="navbar"
          >
            <MenuList tagline={tagline} handleCloseNavbar={handleCloseNavbar} />
          </div>
        </div>
      </nav>
      <Outlet />
    </div>
  );
};

export default Navbar;
