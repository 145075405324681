import { BrowserRouter } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useModalContext } from 'provider/ModalProvider/ModalProvider';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useAppContext } from 'provider/AppProvider/AppProvider';
import UsernameRoute from './UsernameRoute';
import DomainRoute from './DomainRoute';

export const webHostURL = new URL(
  String(process.env.REACT_APP_WEB_HOST ?? 'http://localhost:3000'),
);

const App: FC = () => {
  const { showModal } = useModalContext();
  const { loggedInUser } = useUserContext();
  const { userRole } = useAppContext();
  const isUserCreator = userRole === 'creator';

  const isModalDismissed = localStorage.getItem('isModalDismissed');
  const isModalDismissedStorage =
    isModalDismissed && JSON.parse(isModalDismissed);

  useEffect(() => {
    if (loggedInUser && isUserCreator && !isModalDismissedStorage) {
      showModal(<CreatorWarningModal />);
    }
  }, [loggedInUser, userRole]);

  return (
    <BrowserRouter>
      {window.location.hostname === webHostURL.hostname ? (
        <UsernameRoute />
      ) : (
        <DomainRoute />
      )}
    </BrowserRouter>
  );
};

export default App;

const CreatorWarningModal = () => {
  const { closeModal } = useModalContext();
  return (
    <div className="max-w-md">
      <p>
        You have logged in with your Creator credentials, which will not display
        your content correctly. We recommend to logout and login with a
        different email so you can see the content from a user perspective
      </p>
      <button
        onClick={() => {
          closeModal();
          localStorage.setItem('isModalDismissed', 'true');
        }}
        type="button"
        className="mx-auto ttnk-button"
      >
        OK
      </button>
    </div>
  );
};
