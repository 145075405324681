import VideoPlayer from 'components/modules/VideoPlayer';
import Layout from 'components/template/Layout';
import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetUserDataByUsername,
  useDefaultColorValue,
} from 'services/queries/app';
import Button from 'components/base/Button';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import Preloader from 'components/base/Preloader';
import { getUrlWithProtocol } from 'utils/getUrlWithProtocol';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { StyledBgContainer } from 'pages/Content/Content.styles';
import { PageNotFoundLoggedOut } from 'pages/ActionForms/PromptMessage';
import { useGetOfferByUuid } from '../../services/queries/content';

const SpecialOffer = () => {
  useGetUserDataByUsername();
  const navigate = useNavigate();
  const todayDate = new Date();

  const { uuid, type } = useParams();
  const { loggedInUser } = useUserContext();
  const { status, data } = useGetOfferByUuid(uuid, loggedInUser?.id_token);
  const colors = useDefaultColorValue();
  const {
    setup: { fonts },
    setTitle,
  } = useAppContext();

  const [showPreloader, setShowPreloader] = useState<'loading' | 'success'>(
    'loading',
  );
  const [svgColor, setSvgColor] = useState(colors.medium);

  useEffect(() => {
    setTimeout(() => {
      setShowPreloader('success');
    }, 750);
  }, []);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
    }
  }, [data]);

  if (
    status === 'success' &&
    (typeof data === 'undefined' ||
      new Date(String(data?.release_date)) > todayDate)
  ) {
    return <PageNotFoundLoggedOut />;
  }

  const isLoading = showPreloader === 'loading' || status === 'loading';

  return (
    <Layout>
      {isLoading ? (
        <StyledBgContainer
          bgColor={colors.light}
          className="flex flex-col h-full mx-auto"
        >
          <div className="container mx-auto px-4 md:px-0 pt-11">
            <Preloader />
            <Preloader />
          </div>
        </StyledBgContainer>
      ) : (
        <StyledBgContainer
          bgColor={colors.light}
          className="flex flex-col justify-between h-full"
        >
          <div className="container mx-auto px-0">
            <div className="pt-11">
              {(data?.title ?? '') !== '' && type !== 'questions' ? (
                <div className="flex justify-start items-center pb-4">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    style={{ color: colors.medium }}
                  >
                    <RiArrowLeftSLine
                      size="64"
                      color={svgColor}
                      onMouseEnter={() => setSvgColor(colors.dark)}
                      onMouseLeave={() => setSvgColor(colors.medium)}
                    />
                  </button>
                  <h1
                    className="text-3xl font-bold mb-0 pl-4"
                    style={{ color: colors.dark, fontFamily: fonts.header }}
                  >
                    {data?.title}
                  </h1>
                </div>
              ) : null}
            </div>
            {data?.video_url ? (
              <div className="pt-4">
                <div className="flex flex-col px-4 lg:px-0 items-center justify-center xl:w-[1056px] xl:h-[575px] mx-auto">
                  <VideoPlayer url={data?.video_url ?? ''} />
                </div>
              </div>
            ) : null}

            {!data?.video_url && data?.image_url ? (
              <div className="py-4">
                <div className="flex flex-col px-4 lg:px-0 items-center justify-center xl:w-[1056px] mx-auto">
                  <img
                    className="max-w-full object-scale-down rounded-lg min-h-[100px]"
                    src={data?.image_url}
                    alt="headline-img"
                  />
                </div>
              </div>
            ) : null}

            <div className="inner-html text-black text-lg text-justify w-full mx-auto pt-8 mb-6 ttci-break-word whitespace-pre-wrap px-4">
              {data?.message}
            </div>

            <div className="flex flex-col justify-center items-center p-4 md:p-8 pt-0">
              {(data?.action_bar_text ?? '') !== '' && (
                <div className="w-full flex items-center justify-center md:w-fit py-4 -px-4 md:p-4">
                  {data?.action_bar_url !== '' ? (
                    <a
                      href={getUrlWithProtocol(data?.action_bar_url)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button>{data?.action_bar_text}</Button>
                    </a>
                  ) : (
                    <Button>{data?.action_bar_text}</Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </StyledBgContainer>
      )}
    </Layout>
  );
};

export default SpecialOffer;
