import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getFeaturedContent,
  getPosts,
  getUserDataByDomain,
  getUserDataByUsername,
  signUpMember,
  getAppDataByUUID,
} from 'services/api/app';
import {
  deleteAccount,
  getLinkedAccounts,
  resetPassword,
  updateAccountInfo,
} from 'services/api/user';

export const useDefaultColorValue = () => {
  const {
    setup: { colors },
  } = useAppContext();

  colors.light = colors.light || '#D3D3D3';
  colors.medium = colors.medium || '#939799';
  colors.dark = colors.dark || '#5A5A5A';

  return colors;
};

export const useGetUserDataByUsername = () => {
  const {
    setSetup,
    setProfile,
    setAbout,
    setUuid,
    setUsername,
    setName,
    setIsSubscribed,
    setBaseURL,
    setUserRole,
  } = useAppContext();
  const { username } = useParams();
  const { loggedInUser } = useUserContext();

  return useQuery(
    ['getUserDataByUsername', { username, token: loggedInUser?.id_token }],
    async () => {
      const webHostURL =
        process.env.REACT_APP_WEB_HOST ?? 'http://localhost:3000';
      let response;

      // check if running on web host domain
      if (webHostURL === window.location.origin) {
        // use Username
        response = await getUserDataByUsername(
          username,
          loggedInUser?.id_token,
        );

        // To be used by navigate() and <Link>
        // blank if using a domain, /username if using a username
        setBaseURL(`/${String(response.data.username)}`);
        // return response?.data;
      } else {
        // Use URL
        response = await getUserDataByDomain(
          encodeURIComponent(window.location.origin),
          loggedInUser?.id_token,
        );

        // To be used by navigate() and <Link>
        // blank if using a domain, /username if using a username
        setBaseURL('');
      }
      // redirect if username or domain is not found
      if (response?.data.status === 404) {
        window.location.href = `${webHostURL}?error=${encodeURIComponent(
          response?.data.statusText,
        )}`;
      }

      setSetup(response?.data?.setup);
      setProfile(response?.data?.profile);
      setAbout(response?.data?.about);
      setUuid(response?.data.id);
      setUsername(response.data.username);
      setName(response.data.name);
      setIsSubscribed(response.data.user_is_subscribed);
      setUserRole(response.data.user_role);
      return response?.data;
    },
    // {
    //   // staleTime: 1000 * 60,
    //   enabled: true,
    // },
  );
};

export const useGetAppAboutByUUID = () => {
  const { uuid } = useAppContext();
  const { setSetup, setAbout, setup, about } = useAppContext();

  return useQuery(
    ['getAppDataByUUID', { uuid }],
    async () => {
      const response = await getAppDataByUUID(uuid);
      const webHostURL =
        process.env.REACT_APP_WEB_HOST ?? 'http://localhost:3000';

      // redirect if username or domain is not found
      if (response?.data.status === 404) {
        window.location.href = `${webHostURL}?error=${encodeURIComponent(
          response?.data.statusText,
        )}`;
      }

      setAbout({
        ...about,
        privacy_policy_url: response?.data?.about.privacy_policy_url ?? '',
        platform_version: response?.data?.about.platform_version ?? '9.0',
      });
      setSetup({
        ...setup,
        web: response?.data?.setup.web,
      });

      return response?.data;
    },
    // {
    //   // staleTime: 1000 * 60,
    //   // enabled: true,
    // },
  );
};

export const useGetFeaturedContent = () => {
  const { uuid } = useAppContext();
  const { topic_uuid } = useParams();
  const { loggedInUser } = useUserContext();

  return useQuery(
    ['getFeaturedContent', { uuid, topic_uuid, token: loggedInUser?.id_token }],
    getFeaturedContent(
      String(uuid),
      String(topic_uuid),
      String(loggedInUser?.id_token),
    ),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useGetPosts = () => {
  const { uuid } = useAppContext();
  const { loggedInUser } = useUserContext();
  return useQuery(
    ['getPosts', { uuid, token: loggedInUser?.id_token }],
    getPosts(String(uuid), String(loggedInUser?.id_token)),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useSignUp = () => {
  const { uuid } = useAppContext();

  return useMutation(
    // eslint-disable-next-line
    (payload: Record<string, unknown>) => signUpMember(String(uuid))(payload),
    {},
  );
};

export const useUpdateMyAccount = (errorCallback: () => void) => {
  const { uuid: appUuid } = useAppContext();
  const { loggedInUser } = useUserContext();
  const memberUuid = loggedInUser?.data?.sub;

  return useMutation(
    // eslint-disable-next-line
    (payload: Record<string, unknown>) => updateAccountInfo(
        String(appUuid),
        String(memberUuid),
        String(loggedInUser?.id_token),
        JSON.stringify(payload),
      ),
    {
      onSuccess: (e) => {
        if (e?.error) errorCallback();
      },
    },
  );
};

export const useGetLinkedAccounts = () => {
  const { uuid } = useAppContext();
  const { loggedInUser } = useUserContext();
  return useQuery(
    ['getLinkedAccounts', { uuid, token: loggedInUser?.id_token }],
    getLinkedAccounts(
      String(uuid),
      String(loggedInUser?.data?.sub),
      String(loggedInUser?.id_token),
    ),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useResetPassword = (errorCallback: () => void) => {
  const { loggedInUser } = useUserContext();

  return useMutation(
    // eslint-disable-next-line
    (payload: Record<string, unknown>) => resetPassword(
        String(loggedInUser?.id_token),
        JSON.stringify(payload),
      ),
    {
      onSuccess: (e) => {
        if (e?.error) errorCallback();
      },
    },
  );
};

export const useDeleteAccount = (errorCallback: () => void) => {
  const { loggedInUser } = useUserContext();

  return useMutation(
    // eslint-disable-next-line
    () => deleteAccount(
        String(loggedInUser?.data?.sub),
        String(loggedInUser?.id_token),
      ),
    {
      onSuccess: (e) => {
        if (e?.error) errorCallback();
      },
    },
  );
};
