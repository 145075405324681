/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ChildrenProps } from 'provider/AppProvider/AppProvider.interface';
import { useState, useEffect, useMemo, createContext, useContext } from 'react';
import { useAuth } from 'react-oauth2-pkce';
import jwtDecode from 'jwt-decode';
import { webHostURL } from 'pages';
import { IUserContext } from './UserProvider.interface';

interface LoggedInUser {
  id_token: string;
  data: {
    name?: string;
    sub: string;
  };
}

export const authDefaultContext: Partial<IUserContext> = {
  loggedInUser: {},
  isLoggedIn: false,
};

export const UserContext = createContext<IUserContext>(
  authDefaultContext as IUserContext,
);

const UserProvider = ({ children }: ChildrenProps) => {
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [logoutRedirectUri, setLogoutRedirectUri] = useState(null);
  const { authService } = useAuth();
  const { id_token } = authService.getAuthTokens();
  const isLoggedIn = authService.isAuthenticated();

  const login = async () => {
    localStorage.setItem('fromLogin', 'true');
    setIsLoading(true);

    void authService.login().then(() => setIsLoading(false));

    const data: { name?: string; length: number; sub: string } =
      jwtDecode(id_token);

    if ((data?.name ?? '') !== '') {
      localStorage.setItem('name', data?.name ?? '');
    }

    localStorage.setItem('lastUserSession', data?.sub ?? '');
  };

  const logout = async () => {
    setIsLoading(true);

    const redirectUri = `?redirect_uri=${
      logoutRedirectUri ?? `${window.location.origin}/`
    }`;

    const fullRedirectUri = `${
      process.env.REACT_APP_SSO_HOST ?? 'https://ae-sso.techtank.ca'
    }/users/sign_out${redirectUri}`;

    if (window.location.hostname === webHostURL.hostname) {
      await fetch(
        `${
          process.env.REACT_APP_SSO_HOST ?? 'https://ae-sso.techtank.ca'
        }/users/sign_out`,
        {
          credentials: 'include',
          method: 'GET',
        },
      );
    }

    localStorage.removeItem('name');
    localStorage.removeItem('timezone');
    localStorage.removeItem('isUpdatingMember');
    localStorage.removeItem('isModalDismissed');

    void authService.logout().then(() => {
      setLoggedInUser(null);
      setIsLoading(false);
    });

    if (window.location.hostname !== webHostURL.hostname)
      window.location.href = fullRedirectUri;
  };

  useEffect(() => {
    if ((id_token ?? '') !== '') {
      const data: { name?: string; length: number; sub: string } =
        jwtDecode(id_token);

      setLoggedInUser({ id_token, data });

      if ((localStorage?.getItem('name') ?? '') === '') {
        localStorage.setItem('name', data?.name ?? '');
      }

      if ((localStorage?.getItem('lastUserSession') ?? '') === '') {
        localStorage.setItem('lastUserSession', data?.sub ?? '');
      }

      if ((localStorage?.getItem('isModalDismissed') ?? '') === '') {
        localStorage.setItem('isModalDismissed', 'false');
      }
    }
  }, [id_token]);

  const value = useMemo(
    () => ({
      loggedInUser,
      setLoggedInUser,
      isLoading,
      isLoggedIn,
      login,
      logout,
      logoutRedirectUri,
      setLogoutRedirectUri,
    }),
    [loggedInUser, logoutRedirectUri, setLogoutRedirectUri],
  );

  return (
    <UserContext.Provider value={value as unknown as IUserContext}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
export const useUserContext = () => useContext(UserContext);
